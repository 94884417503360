@media (max-width: 600px) {
  .keypad {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30px, 1fr)); /* Responsive grid columns */
    justify-content: center;
  }

  .key {
    height: 40px;
    max-width: 85%; /* Changed to a percentage for responsiveness */
    font-size: 12px; /* Smaller font size for mobile */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    font-weight: bold;
  }

  /* First row: keys 1-10 */
   .key:nth-child(n + 1):nth-child(-n + 10) {
    grid-row: 1;
  }

  /* Second row: keys 11-19 (9 elements in this row) */
  .key:nth-child(n + 11):nth-child(-n + 19) {
    grid-row: 2;
    grid-column: span 1;
    margin-left: calc(100% / 1.7); /* Half the width of a 9-column grid */  }

  /* Third row: keys 20-26 */
  .key:nth-child(n + 20):nth-child(-n + 26) {
    grid-row: 3;
    grid-column: span 1;
    margin-left: calc(100% / 1.7); /* Half the width of a 9-column grid */  }

  .key.enter-key {
    grid-row: 3;
    grid-column: 1;
    max-width: 150%;
    width: 130%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 12px; /* Adjust this value as needed */
  }

  .key.delete-key {
    grid-row: 3;
    grid-column: 9;
    margin-left: calc(100% / 1.7); /* Half the width of a 9-column grid */
    max-width: 150%;
    width: 130%;
    display: flex;
    font-weight: bold;
  }
  /* Adjust icon sizes */
  .key svg {
    width: 15px;
    height: 15px;
  }

  .key.enter-key svg {
    width: 15px;
    height: 15px;
    stroke: black; /* Add a stroke */
    stroke-width: 1; /* Adjust the stroke thickness */
  }

  .key.enter-key.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  /* Optional: Add a loading spinner */
  .key.enter-key.disabled::after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 8px;
    border: 2px solid #5ac85a;
    border-radius: 100%;
    border-top-color: #5ac85a;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .key.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .keypad.submitting {
    pointer-events: none;
  }
}
