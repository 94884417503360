.notifications-page {
    padding-top: 0;
    padding-bottom: 70px;
    min-height: 100vh;
    background-color: #fff;
}

.notifications-container {
    max-width: 480px;
    margin: auto;
    padding: 20px;
}

.loading, .error, .no-notifications {
    text-align: center;
    padding: 20px;
    color: #666;
}

.error {
    color: #ff4444;
}

@media (max-width: 768px) {
    .notifications-container {
        padding: 10px;
    }
} 