.comment-component {
    display: flex;
    border: none;
    padding: 10px;
    border-bottom: 2px solid #ddd;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

.user-avatar-container {
    width: 60px;
    margin-right: 10px;
}

.user-avatar {
    width: 100%;
    height: auto;
    border-radius: 50%;
    cursor: pointer;
}

.comment-content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.comment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.user-info {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-start;
}

.user-name {
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
}

.time {
    font-size: 14px;
}

.comment-content {
    margin-top: 5px;
}

.comment-content p {
    margin: 0;
    text-align: left;
    font-size: 14px;
}

.comment-footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
}

.comment-actions {
    display: flex;
    align-items: center;
    gap: 15px;
}

.heart-icon, .comment-icon, .delete-icon {
    cursor: pointer;
}

.comment-icon {
    font-size: 21px;
    transform: scaleX(-0.95);
    margin-left: 16px;
    stroke-width: 0px;
    color: #555;
}

.comments-count {
    margin-left: 4px;
    font-size: 14px;
    color: #555;
}

.heart-icon.liked {
    color: red;
}

.likes-count {
    margin-left: 4px;
    cursor: pointer;
    font-size: 14px;

}

@media (max-width: 500px) {
    .comment-component {
        flex-direction: row;
    }

    .user-avatar-container {
        width: 40px;
        margin-right: 10px;
    }

    .comment-content-wrapper {
        width: calc(100% - 50px);
    }

    .comment-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .time {
        font-size: 12px;
    }
}

.spacing {
    height: 16px; /* Adjust this value to increase or decrease the spacing */
}