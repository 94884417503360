.navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 1000;
  border-top: 1px solid #eee;
}

@media (min-width: 768px) {
  .navbar {
    top: 50px; /* Height of StaticNavHeader */
    bottom: auto;
    border-top: none;
    border-bottom: 1px solid #eee;
  }
  
  body {
    margin-top: 68px; /* Add height of NavHeader */
  }
}

.navbar-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px 0;
  max-width: 480px;
  margin: 0 auto;
}

.navbar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #555;
  flex: 1;
  padding: 4px 0;
}

.navbar-item.active {
  color: #5a9254;
}

.feed-icon, .nav-icon {
  font-size: 24px;
}

.wordle-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.label {
  font-size: 12px;
  margin-top: 4px;
}

.label.active {
  color: #5a9254;
  font-weight: bold;
}

/* Add specific styling for notification icon when active */
.navbar-item.active .notification-icon {
  color: #5a9254;
}

/* iOS safe area support */
@supports (-webkit-touch-callout: none) {
  .navbar {
    padding-bottom: env(safe-area-inset-bottom);
  }
}
