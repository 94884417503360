.guess-distribution-overview-wrapper {
    width: 100vw; /* Full viewport width */
    overflow-x: hidden; /* Hide horizontal overflow */
    position: relative; /* For positioning the pseudo-elements */
}

.guess-distribution-overview-container {
    width: 100%; /* Full width for mobile view */
    max-width: 500px; /* Max width for larger screens */
    margin: 0 auto; /* Center the container */
    padding: 20px; /* Add some padding */
    background-color: transparent; /* Remove shading */
    text-align: center; /* Center the text */
    position: relative; /* For positioning the separator lines */
    padding-right: 20px; /* Add padding to the right side */
}

.guess-distribution-overview-wrapper::before,
.guess-distribution-overview-wrapper::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 1px; /* Line height */
    background-color: #ccc; /* Line color */
}

.guess-distribution-overview-wrapper::before {
    top: 0; /* Position the line at the top */
}

.guess-distribution-overview-wrapper::after {
    bottom: 0; /* Position the line at the bottom */
}

.guess-distribution-overview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.histogram-bar {
    display: flex;
    align-items: center;
    margin-bottom: 2px; /* Reduce margin between bars */
    width: 100%; /* Ensure each bar takes up full width of the container */
    position: relative; /* To position guess count correctly */
}

.guess-number {
    width: 40px; /* Fixed width for guess number */
    text-align: right; /* Align number to the right */
    color: #333; /* Set color to match design */
    font-weight: bold; /* Make the numbers bold */
    font-size: 12px;
}

.bar {
    flex-grow: 1;
    background-color: transparent; /* No background shading */
    overflow: hidden;
    height: 20px;
    margin: 0 10px; /* Add some margin for spacing */
    position: relative; /* For positioning the bar-fill inside */
}

.bar-fill {
    height: 100%;
    background-color: #888; /* Bar fill color */
    min-width: 20px; /* Set a minimum width for the bar */
    position: absolute; /* Position bar fill inside the bar */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    width: 0; /* Default width, to be set dynamically */
    display: flex;
    align-items: center; /* Center text vertically */
    justify-content: flex-end; /* Align text to the right */
    color: white; /* Set text color */
    padding-right: 5px; /* Padding for spacing inside bar fill */
    box-sizing: border-box; /* Include padding in width calculation */
}

.max-bar .bar-fill {
    background-color: green; /* Highlight max value in green */
}

.guess-count {
    position: absolute; /* Position number inside the bar-fill */
    right: 7px; /* Add some space from the right edge */
    color: white; /* Set color to white for visibility */
    font-weight: bold; /* Make number bold for better visibility */
    z-index: 1; /* Ensure number is above the bar fill */
    font-size: 12px;
}

@media (max-width: 768px) {
    .guess-distribution-overview-container {
        padding: 10px; /* Adjust padding for mobile */
    }

    .histogram-bar {
        flex-direction: row; /* Stack the number and bar on top of each other */
        gap: 10px;
    }

    .bar {
        margin: 1px; /* Remove margin for mobile view */
        max-width: 60%; /* Full width of the container */
    }

    .bar-fill {
        min-width: 20px; /* Allow bars to shrink below 20px if needed */
    }
}

/* Add this CSS rule */
.guess-distribution-overview-wrapper h3 {
    margin-top: 5px;
    text-align: left; /* Align text to the left */
    margin-left: 32px; /* Match the width of .guess-number */
}
