.static-nav-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1000;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #eee;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  max-width: 480px;
  margin: 0 auto;
}

.header-left {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-image {
  height: 50px;
  width: auto;
  object-fit: contain;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.nav-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.nav-icon-container:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.nav-icon {
  font-size: 24px;
  color: #555;
}

.profile-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.nav-icon.active {
  color: #5a9254 !important;
}

/* Mobile styles */
@media (max-width: 768px) {
  .header-content {
    padding: 0 8px;
  }

  .nav-icon-container {
    padding: 6px;
  }
}

/* Ensure body padding accounts for header */
body:not(.viewing-comments) {
  padding-top: 50px;
}

body.viewing-comments {
  padding-top: 0;
  margin-top: 0px;
}
