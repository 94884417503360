.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  overflow-x: hidden;
}

.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; /* This ensures everything inside is centered */
}

.icon {
  margin-bottom: 20px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 20px);
  grid-gap: 2px;
  margin-top: -50px;
  margin-left: -25px;
}

.grid-cell {
  width: 20px;
  height: 20px;
  background-color: #a1a1a1;
}

/* Apply green color to the bottom row */
.grid-cell:nth-child(6),
.grid-cell:nth-child(7),
.grid-cell:nth-child(8),
.grid-cell:nth-child(9) {
  background-color: #5ac85a; /* Green */
}

/* Apply yellow color to the middle row */
.grid-cell:nth-child(5) {
  background-color: #e2cc68; /* Yellow */
}

.title {
  font-size: 36px;
  font-weight: bold;
  margin-top: -20px;
  justify-content: center;
}

.description {
  font-size: 16px;
  margin: 10px 0;
  text-align: center;
}

.play-button {
  width: 150px;
  padding: 12px;
  font-size: 18px;
  background: linear-gradient(45deg, #4CAF50, #45a049);
  color: white;
  border: none;
  border-radius: 25px;
  margin: 20px 0;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.play-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.play-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.login-button {
  width: 150px;
  padding: 10px;
  font-size: 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 25px;
  margin: 10px 0;
  cursor: pointer;
}

@media(max-width: 600px) {
  .grid{
    padding-left: 35%;
  }

  .title {
    padding-left: 0;
    padding-top: 12%;
  }

  .description {
    padding-left: 0;
    padding-bottom: 10%;
  }

  .overlay {
    padding-top: 6%;
  }
}

.wordle-container {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100 - 120px); /* Uses the custom --vh variable */
  justify-content: space-between;
  padding-bottom: 60px;
}

/* For tablets and desktops */
@media (min-width: 768px) {
  .wordle-container {
    height: calc(100vh - 250px); /* Adjusted height for tablets and desktops */
  }
}

.wordle-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  min-height: 0;
}

.keypad {
  flex-shrink: 0; /* Prevent the keypad from shrinking */
}
