.feed-container {
    margin-top: 8px; /* Add space for group tabs */
    max-width: 480px; /* Keep the container max width */
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow */
    width: 100%; /* Ensure full width */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    position: relative;
}

@media (max-width: 768px) {
    .feed-container {
        padding-bottom: 60px; /* Add padding to prevent overlap with the navbar */
    }
}

.feed-container p {
    text-align: left;
    font-size: 14px;
    color: #555;
    margin: 10px 0;
}

.load-more-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: relative; /* Needed for z-index to work */
}

.load-more-button:disabled {
    background-color: #ccc;
}

.feed-component {
    position: relative;
}

/* Add styles for group tabs container */
.group-tabs-container {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 8px;
}
