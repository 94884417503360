.animated-greeting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
    height: 100vh;
    position: relative;
}

.greeting-container {
    margin-top: 20vh;
    min-height: 100px;
}

.content-container {
    position: relative;
    min-height: 150px;
}

.logo-image {
    height: 50px;
    margin-left: 10px;
    vertical-align: middle;
    opacity: 0;
}

.logo-word {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
}

.greeting-text {
    font-size: 3.5rem;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.name-text {
    font-size: 4rem;
    margin: 1rem 0 2rem 0;
    background: linear-gradient(45deg, #FF6B6B, #4ECDC4);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    opacity: 0;
}

.word {
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    margin-right: 8px;
    display: inline-block;
}

.subtext {
    opacity: 0;
    margin-top: 1rem;
    font-size: 1.4rem;
    color: #666;
}

.play-button {
    opacity: 0;
    width: 180px;
    padding: 15px;
    font-size: 1.2rem;
    background: linear-gradient(45deg, #4CAF50, #45a049);
    color: white;
    border: none;
    border-radius: 25px;
    margin: 20px 0;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.play-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.fade-in {
    animation: fadeIn 0.8s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.login-button-container {
    opacity: 0;
    margin-top: 20px;
    display: flex; /* Added to enable flexbox */
    justify-content: center; /* Centers the content */
}

.brand-text {
    font-size: 4rem;
    margin: 1rem 0 1rem 0;
    background: linear-gradient(45deg, #FF6B6B, #4ECDC4);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    opacity: 0;
    font-weight: bold;
}

.login-prompt {
    display: block;
    margin-top: 4rem;
}

@media (max-width: 768px) {
    .greeting-container {
        margin-top: 15vh;
    }

    .logo-image {
        height: 100px;
    }
    
    .greeting-text {
        font-size: 2rem;
    }
    
    .name-text {
        font-size: 3rem;
    }
    
    .subtext {
        font-size: 1.2rem;
    }
    
    .brand-text {
        font-size: 3rem;
    }
}
