.post-detail {
    max-width: 480px;
    margin: 0 auto;
}

.post-content {
    margin-bottom: 10px;
}

.post-message {
    text-align: left;
}

.wordle-grid {
    display: flex;
    flex-direction: column;
}

.wordle-row {
    display: flex;
}



.comments-section form {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
}

.comments-section input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.comments-section button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.comments-section button:hover {
    background-color: #0056b3;
}