/* UserOverview.css */

/* Existing styles */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
}

.image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 8px;
}

.name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.stats {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
}

.stat {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 4px;
  margin-bottom: 1px;
}

.separator {
  margin: 0 8px;
}

.additional-stats {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px;
}

.stat-number {
  font-size: 18px;
  font-weight: normal; /* Keep stat numbers normal */
  text-align: center; /* Center the text for better alignment */
}

.stat-text {
  font-size: 14px;
  color: #555;
  font-weight: bold; /* Make texts bold */
}

.stat-subtext {
  color: #555;
  font-size: 14px;
  font-weight: bold; /* Make "Streak" bold */
}

.aggregate-battle-score {
  display: flex;
  align-items: center;
  gap: 4px; /* Adjust the gap as needed */
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 4px 12px;
  font-size: 12px;
  margin-top: 8px;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.modal .aggregate-battle-score {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 4px 12px;
  font-size: 12px;
  margin-top: 8px;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.aggregate-battle-score .swords-icon {
  font-size: 16px;
  margin: 0 4px;
}

.aggregate-battle-score .score {
  margin: 0 2px; /* Adds space before and after the score */
  font-size: 14px;
}

.aggregate-battle-score .separator {
  margin: 0 2px;
  font-size: 14px;
}

.aggregate-battle-score .user-name {
  margin-right: 2px;  
  font-weight: normal;
}