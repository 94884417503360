.likes-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.likes-container {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.likes-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  position: relative;
  border-radius: 10px 10px 0 0;
}

.likes-header h2 {
  margin: 15px;
  font-size: 16px;
}

.back-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #333;
}

.likes-list {
  padding: 20px;
  padding-top:0px;
  padding-bottom:5px;
  overflow-y: auto;
}

.likes-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.likes-item:last-child {
  border-bottom: none;
}

.user-avatar-likes {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
