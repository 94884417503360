.tutorial-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    pointer-events: none;
  }
  
  .overlay-section {
    width: 100%;
    transition: all 0.3s ease;
    z-index: 1001;
    pointer-events: auto;
  }
  
  .overlay-section.dark {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .overlay-section:not(.dark) {
    background: transparent !important;
  }
  
  .tutorial-content {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .tutorial-step {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 400px;
    text-align: center;
    z-index: 1002;
    pointer-events: auto;
  }
  
  .tutorial-step.active {
    display: block;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .tutorial-button {
    margin-top: 15px;
    padding: 8px 20px;
    background-color: #538d4e;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .tutorial-button:hover {
    background-color: #437c3d;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateX(-50%) translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }