/* Base styles for all screen sizes */
.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 24px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  aspect-ratio: 1 / 1;
}

.row.daily div,
.row.solution div {
  font-weight: bold;
}

/* Mobile-specific adjustments */
@media (max-width: 600px) {
  .row div {
    width: min(calc(16vw - 4px), calc((var(--vh, 1vh) * 100 - 300px) / 6 - 8px));
    height: min(calc(16vw - 4px), calc((var(--vh, 1vh) * 100 - 300px) / 6 - 8px));
    max-width: 60px;
    max-height: 60px;
    font-size: min(calc((16vw - 4px) * 0.6), 24px);
  }
}
