.post-component {
    display: flex;
    border: none;
    padding: 10px;
    position: relative; /* Ensure positioning for the pseudo-element */
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    max-width: 100%; /* Ensure it takes full width */
    overflow-x: hidden;
}

.post-component::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; /* Position at the bottom */
    height: 2px; /* Height of the separator */
    background-color: #f0f0f0; /* Same color as the separator */
}

.user-avatar-container {
    width: 60px; /* Adjust as needed */
    margin-right: 10px;
}

.user-avatar {
    width: 100%;
    height: auto;
    border-radius: 50%;
    cursor: pointer;
}

.post-content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.post-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.user-info {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-start;
}

.user-name {
    font-weight: bold;
    cursor: pointer;
    margin-right: 2px; /* Reduced margin to bring name closer to avatar */
}

.user-info .separator {
    margin: 0 3px; /* Margin around the dot */
}

.user-visits {
    margin: 0; /* No margin to ensure visits are right next to the dot */
    color: #000000;
    font-size: 14px;
}

.time {
    font-size: 10px;
    color: #555;
    margin-left: 15px;
    position: absolute;
    right: 0;
    margin-right: 5px; /* Reduced margin from the right edge */
}

.wordle-grid {
    width: 100%;
    display: flex; 
    flex-direction: column; 
    align-items: flex-start; 
    gap: 2px; 
}

.wordle-grid p {
    width: 100%;
    display: flex; 
    flex-direction: column; 
    align-items: left;
    margin-right: 300px;
}

.wordle-row {
    display: flex;
    gap: 2px;
    width: 100%;
    justify-content: left; 
}

.wordle-cell {
    width: 15px;
    height: 13px;
    background-color: grey;
    box-sizing: border-box;
}

.wordle-cell.green {
    background-color: #5ac85a;
}

.wordle-cell.yellow {
    background-color: #e2cc68;
}

.wordle-cell.grey {
    background-color: #a1a1a1;
}

.post-footer {
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;
}

.heart-icon {
    cursor: pointer;
    color: #555;
}

.heart-icon.liked {
    color: red; 
}

.likes-count {
    margin-left: 4px;
    cursor: pointer;
    color: #555;

}

.delete-icon {
    margin-left: auto;
    color: lightcoral;
    cursor: pointer;
}

.delete-dialog {
    background-color: white; /* Removed transparency */
    width: 300px; /* Increased width */
    text-align: center; /* Center align text */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    position: fixed; /* Fixed position to overlay on top */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for centering */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
    z-index: 1000; /* Ensure it appears above other content */
}

.delete-dialog h2 {
    margin-bottom: 20px; /* Space below title */
    font-size: 20px; /* Title font size */
}

.feed-container .delete-dialog p {
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;
}

.delete-dialog-buttons {
    display: flex;
    gap: 20px;
}

.delete-dialog button {
    background-color: white; /* White background for both buttons */
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.delete-dialog button.cancel-button {
    background-color: white;
    color: black;
    border: 1px solid black;
}

.delete-dialog button.delete-button {
    color: red; /* Red text for delete button */
    border: 1px solid black;
}

/* Desktop View */
@media (min-width: 500px) {
    .post-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .user-info {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: flex-start; /* Center content within user-info */
    }

    .user-avatar {
        margin-right: 10px; /* Consistent margin between avatar and name */
    }

    .user-name {
        font-weight: bold;
        cursor: pointer;
        white-space: nowrap; /* Prevent name from wrapping */
        overflow: hidden; /* Hide overflow */
        text-overflow: ellipsis; /* Add ellipsis for overflow text */
    }

    .user-visits {
        margin: 0; /* Adjust margin as needed */
    }

    .time {
        font-size: 14px; /* Adjust font size if needed */
    }
}

/* Mobile View */
@media (max-width: 500px) {
    .post-component {
        flex-direction: row;
    }

    .user-avatar-container {
        width: 40px; /* Adjust for mobile */
        margin-right: 10px;
    }

    .post-content-wrapper {
        width: calc(100% - 50px); /* Adjust based on avatar container width */
    }


    .post-header {
        display: flex;
        align-items: left;
        justify-content: left; /* Space out avatar, name, and time */
        position: relative;
        width: 100%;
    }

    .user-info {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: flex-start; /* Center content within user-info */
    }

    .user-avatar {
        width: 100%;
        height: auto;
    }

    .user-name {
        font-weight: bold;
        cursor: pointer;
        white-space: normal;    /* Allow the name to wrap */
        overflow: hidden;       /* Hide overflow */
        text-overflow: ellipsis; /* Add ellipsis for overflow text */
        word-break: break-word; /* Break long words if needed */
        text-align: center;     /* Center text if it stacks */
    }

    .user-visits {
        margin: 0; /* Ensure visits are right below the name */
    }

    .time {
        position: absolute;
        font-size: 14px; /* Adjust font size if needed */
    }
}


.comment {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.comment .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.comment-content {
    flex: 1;
}

.comment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.comment-footer {
    display: flex;
    align-items: center;
    margin-top: 5px;
}


.battle-icon {
    cursor: pointer;
    margin-right: 5px;
    margin-left: 16px;
    width: 20px;
    height: 20px;
    color: #555;
}

.battle-icon.challenger_won {
    color: #4fb24f; /* Green color from Wordle grid */
    fill: #5ac85a;
}

.battle-icon.defender_won {
    color: lightcoral; /* Red color, same as delete icon */
    fill: lightcoral;
}

.battle-icon.in_progress {
    color: #ceba5d; /* Yellow color from Wordle grid */
    fill: #f5de77;
}

.feed-date-header {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #f0f0f0;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    width: 100%; /* Take full width */
    box-sizing: border-box; /* Include padding in width calculation */
}

.delete-dialog button.play-now {
    background-color: #4CAF50;

}

.post-message {
    margin-bottom: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 14px;
    line-height: 1.4;
}

@keyframes wiggle {
    0%, 100% { transform: rotate(0deg) scale(1); color: #555; }
    25% { transform: rotate(-10deg) scale(1.5); color: #4CAF50; }
    50% { transform: rotate(0deg) scale(1); color: #555; }
    75% { transform: rotate(10deg) scale(1.5); color: lightcoral; }
}

@keyframes wiggleWithGap {
    0%, 50% { transform: rotate(0deg) scale(1); color: #555; }
    12.5% { transform: rotate(-10deg) scale(2); color: #4CAF50; }
    25% { transform: rotate(0deg) scale(1); color: #555; }
    37.5% { transform: rotate(10deg) scale(2); color: lightcoral; }
}

.battle-icon-animate {
    animation: wiggleWithGap 2s ease-in-out;
    animation-iteration-count: 3;
    animation-delay: 1s;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
}

.battle-icon-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.battle-text-bubble {
  position: absolute;
  color: white;
  font-size: 12px;
  white-space: nowrap;
  left: calc(100% + 5px);
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  clip-path: polygon(
    20% 0%,
    20% 10%,
    100% 10%,
    100% 90%,
    20% 90%,
    20% 100%,
    0% 50%,
    20% 0%
  );
  background: #4CAF50;
  padding: 6px 12px 6px 16px;
}

.battle-icon-animate + .battle-text-bubble {
  opacity: 1;
  animation: slideLeftRight 2s ease-in-out infinite;
}

@keyframes slideLeftRight {
  0%, 100% { 
    transform: translateY(-50%) translateX(0);
    opacity: 1;
  }
  50% { 
    transform: translateY(-50%) translateX(10px);
    opacity: 0.8;
  }
}

