@media (max-width: 600px) {
    .grid-wordle {
        padding-top: 1%;
    }
}

.row.daily div {
    background: white;
    color: black;
    border: 1px solid #ddd;
}

.row.solution div {
    border: 1px solid #ddd;
}

.grid-wordle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row {
  display: flex;
  justify-content: center;
}

.row > div {
  aspect-ratio: 1 / 1;
  max-width: 60px;
  width: 100%;
  font-size: calc(16px + 2vmin);
}

@media (max-height: 600px) {
  .row > div {
    aspect-ratio: 1 / 0.8; /* Make cells slightly shorter */
  }
}

.grid-wordle.submitting .row.current {
  opacity: 0.6;
}

.grid-wordle.submitting .row.current div {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
