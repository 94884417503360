.notification-card {
    display: flex;
    border: none;
    padding: 10px 0 10px 10px;
    border-bottom: 2px solid #ddd;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    cursor: pointer;
    transition: background-color 0.2s;
}

.notification-card:hover {
    background-color: #f5f5f5;
}

.notification-card.unread {
    background-color: #f0f7ff;
}

.notification-card.unread:hover {
    background-color: #e5f1ff;
}

.user-avatar-container {
    width: 60px;
    margin-right: 10px;
}

.notification-card .user-avatar {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.notification-content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.notification-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.user-info {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-start;
    gap: 5px;
}

.notification-card .user-name {
    font-weight: bold;
    margin-right: 0px;
    font-size: 14px;
}

.time {
    font-size: 14px;
    color: #666;
}

.notification-content {
    margin-top: 5px;
}

.notification-content p {
    margin: 0;
    text-align: left;
    font-size: 14px;
}

.notification-type-icon {
    margin-right: 8px;
    vertical-align: middle;
}

.notification-type-icon.battle {
    color: #ff4081;
}

.notification-action {
    font-size: 14px;
    margin-right: 10px;
}

@media (max-width: 500px) {
    .notification-card {
        flex-direction: row;
    }

    .user-avatar-container {
        width: 40px;
        margin-right: 10px;
    }

    .notification-content-wrapper {
        width: calc(100% - 50px);
    }

    .notification-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .time {
        font-size: 12px;
    }
} 