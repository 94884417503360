.static-nav-header h1 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
}

.leaderboard-page {
    max-width: 480px;
    margin: 0 auto;
    padding: 0 16px;
    position: relative;
}

.leaderboard-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 140px);
    font-size: 1.2rem;
}

.leaderboard-table {
    overflow: hidden;
}

.table-header {
    display: grid;
    grid-template-columns: 50px 1fr 60px;
    padding: 12px 16px;
    background-color: #f5f5f5;
    border-radius: 8px 8px 0 0;
    font-weight: bold;
    font-size: 0.9rem;
    align-items: center;
}

.table-body {
    border: 1px solid #e0e0e0;
    border-radius: 0 0 8px 8px;
}

.table-row {
    display: grid;
    grid-template-columns: 50px 1fr 60px;
    padding: 12px 16px;
    border-bottom: 1px solid #e0e0e0;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s;
}

.table-row:last-child {
    border-bottom: none;
}

.table-row:hover {
    background-color: #f8f8f8;
}

.rank-column {
    font-weight: 600;
    font-size: 0.9rem;
}

.name-column {
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: 0;
}

.name-column span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9rem;
}

.streak-column {
    text-align: right;
    font-weight: 600;
    font-size: 0.9rem;
}

.leaderboard-table .user-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
}

/* Make navbar sticky on mobile */
@media screen and (max-width: 768px) {
    .navbar {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        z-index: 1000;
        border-top: 1px solid #eee;
    }
}

/* Add new class for tabs container */
.tabs-container {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
}

/* Add new class for scrollable content */
.table-container {
    height: calc(100vh - 220px);
    overflow-y: auto;
    padding-bottom: 70px;
}

/* Add this new style */
.table-row.current-user {
    background-color: #fff9c4;  /* Light yellow color */
}

.table-row.current-user:hover {
    background-color: #fff59d;  /* Slightly darker yellow for hover */
} 